import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const unifyEmbedProps = {
  token: "at:",
  host: "dt-no-code.unifyapp.com",
};
const pageProps = {
  props: {
    overview: {
      ...unifyEmbedProps,
      pageId: "overview",
      interfaceId: "dt_app",
    },
    mobile: {
      ...unifyEmbedProps,
      pageId: "assets_mobile",
      interfaceId: "dt_app",
    },
    fixedLine: {
      ...unifyEmbedProps,
      pageId: "assets_fixedLine",
      interfaceId: "dt_app",
    },
  },
  loaders: {},
};

function getMsisdnNumber() {
  //eg /assets/mobile/123456789 or /assets/fixed-line/123456789?wef
  // return the id part of the path
  return window.location.pathname.split("/")[3];
}

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((application) =>
  registerApplication({
    ...application,
    customProps: (name, location) => {
      if (name === "@unify-apps/matrix-spa") {
        if (location.pathname.includes("/assets/mobile")) {
          return { ...pageProps.props.mobile, msisdnNumber: getMsisdnNumber() };
        }

        if (location.pathname.includes("/assets/fixed-line")) {
          return {
            ...pageProps.props.fixedLine,
            msisdnNumber: getMsisdnNumber(),
          };
        }
        return pageProps.props.overview;
      }
      return {};
    },
  })
);
layoutEngine.activate();
start();

window.addEventListener("single-spa:routing-event", (evt) => {
  console.log("single-spa finished mounting/unmounting applications!");
  console.log('single-spa:routing-event', evt); // PopStateEvent

  // @ts-ignore
  const newUrl = evt.detail.newUrl;
  console.log('newUrl', newUrl);

  if(newUrl.includes('/assets/mobile') || newUrl.includes('/assets/fixed-network')) {
    document.querySelectorAll("#mvContainer__container").forEach((node) => {
        console.log("visibility: hiding node", node);
        (node as HTMLElement).style.display = "none";
    });
    console.log('newUrl: mobile');
  } else {
    document.querySelectorAll("#mvContainer__container").forEach((node) => {
        console.log("visibility: showing node", node);
        (node as HTMLElement).style.display = "block";
    });
    console.log('newUrl: overview');
  }
});
